/*! ------------------------------------------------
 * Project Name: Mixio Coming Soon Template
 * Project Description: Mixio - awesome coming soon template to kick-start your project
 * Tags: mixio, coming soon, under construction, template, coming soon page, html5, css3
 * Version: 1.0.0
 * Build Date: July 2018
 * Last Update: July 2018
 * This product is available exclusively on Themeforest
 * Author: mix_design
 * Author URI: http://mixdesign.club
 * File name: fonts.css
 * ------------------------------------------------
 *
 * ------------------------------------------------
 * Table of Contents
 * ------------------------------------------------
 *  1. Ionocons Font
 *  2. Raleway Font
 *  3. Playfair Display Font
 * ------------------------------------------------
 * Table of Contents End
 * ------------------------------------------------ */

// Import Bourbon
@import "bourbon";

/* Ionicons Font */
@import "../bower_components/Ionicons/scss/ionicons";

/* Frank Ruhl Libre Font */
@import url("https://fonts.googleapis.com/css?family=Frank+Ruhl+Libre");



// Regular > Light
//@font-face {
//  font-family: "YakuHanJP";
//  font-style: normal;
//  font-weight: 200;
//  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Light.eot");
//  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"),
//    url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff");
//  font-display: swap;
//}


/* Raleway Font */
//@include font-face("Raleway", "../fonts/RalewayRegular/RalewayRegular", 400, $file-formats: eot woff ttf);
//@include font-face("Raleway", "../fonts/RalewayMedium/RalewayMedium", 500, $file-formats: eot woff ttf);
//@include font-face("Raleway", "../fonts/RalewaySemiBold/RalewaySemiBold-LiningNums", 600, normal, $file-formats: eot woff woff2 ttf);
//@include font-face("Raleway", "../fonts/RalewayBold/RalewayBold", 700, $file-formats: eot woff ttf);
//@include font-face("Raleway", "../fonts/RalewayExtraBold/RalewayExtraBold", 800, $file-formats: eot woff ttf);

/* Playfair Display Font */
@include font-face("PlayfairDisplay", "../fonts/PlayfairDisplay-Bold/PlayfairDisplay-Bold-LiningNums", 700, normal, $file-formats: eot woff woff2 ttf);
@include font-face("PlayfairDisplay", "../fonts/PlayfairDisplay-BoldItalic/PlayfairDisplay-BoldItalic", 700, italic, $file-formats: eot woff ttf);
@include font-face("PlayfairDisplay", "../fonts/PlayfairDisplay-Italic/PlayfairDisplay-Italic", 400, italic, $file-formats: eot woff ttf);
